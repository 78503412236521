import { useState } from 'react'
import useIsomorphicLayoutEffect from './useIsomorphicLayoutEffect'


const mediaQuery = '(prefers-reduced-motion: reduce)'

const usePrefersReducedMotion = () => {
  const [ prefersReducedMotion, setPrefersReducedMotion ] = useState(false)

  useIsomorphicLayoutEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQuery)

    const listener = (event) => {
      setPrefersReducedMotion(event.matches)
    }

    setPrefersReducedMotion(mediaQueryList.matches)

    if (mediaQueryList.addEventListener) {
      mediaQueryList.addEventListener('change', listener)
    }
    else {
      mediaQueryList.addListener(listener)
    }
    return () => {
      if (mediaQueryList.removeEventListener) {
        mediaQueryList.removeEventListener('change', listener)
      }
      else {
        mediaQueryList.removeListener(listener)
      }
    }
  }, [])

  return prefersReducedMotion
}

export default usePrefersReducedMotion
